import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Filter } from '../models/filter';
import { PhotoElement } from '../models/photo-element';
import { PhotosPageService } from './photos-page.service';

@Injectable({
  providedIn: 'root'
})
export class PhotoFilterService {

  //keeps track of the current filters
  currentFilters = [];
  currentFilters$ = new Subject<Filter[]>();

  filteredPhotoElements$ = new Subject<PhotoElement[]>();

  constructor(private photosPageService: PhotosPageService) {
    this.currentFilters$.subscribe(filters => {
      let photoElements: PhotoElement[] = [];

      if (filters.length > 0) {
        filters.forEach((filter: Filter) => {
          const filteredResult = this.filter(filter);

          photoElements = photoElements.concat(filteredResult);
        });
      } else {
        // reset to the original/initial full list of photo elements
        photoElements = photoElements.concat(this.photosPageService.photoElementList);
      }

      this.filteredPhotoElements$.next(photoElements);
    });
   }


  addPhotoFilter(filter: Filter){
    this.currentFilters.push(filter);
    this.currentFilters$.next(this.currentFilters);
  }

  removePhotoFilter(filter: Filter){
    this.currentFilters.splice(this.currentFilters.indexOf(filter), 1);
    this.currentFilters$.next(this.currentFilters);
  }

  private filter(filter: Filter): PhotoElement[]{
    const filteredPhotoElements: PhotoElement[] = [];

     this.photosPageService.photoLogSectionElements.forEach(pls => {
       pls.photoSetElements.forEach(pse => {
        pse.photoElements.forEach(pe => {
          if (pe.currentStatus === filter.value) {
            filteredPhotoElements.push(pe);
          }
        });
      });
    });

    return filteredPhotoElements;
  }
}
