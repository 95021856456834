
/* eslint-disable max-len */
import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import 'signalr';

import { CaptureUpdate } from '../models/capture-update';
import { LocalStorageService } from './local-storage.service';
import { RegisterService } from './register.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRHubService {
  onUpdateReceived$ = new Subject<CaptureUpdate>();
  onImageReceived$ = new Subject<string>();
  onNewJobCreated$ = new Subject<string>();
  private hubUrl = 'SERVER_URL' + '/signalr';
  private connection: SignalR.Hub.Connection;
  private photoReviewHubProxy: any;

  constructor(
    private zone: NgZone,
    private localStorageService: LocalStorageService
  ) {
    this.localStorageService.siteUrl$.subscribe(value => {
      if (value) {

        this.hubUrl = value + '/signalr';
        this.initializeSignalRConnection(this.hubUrl );
      }

    });
  }

  private initializeSignalRConnection(url: string) {
    console.log('signal r ', this.hubUrl);

    this.connection = $.hubConnection(url, { useDefaultPath: false });
    this.connection.disconnected(() => {
      //TODO: implement retries functionality eg. every one minute
      // this.initializeSignalRConnection();
    });

    this.photoReviewHubProxy = this.connection.createHubProxy('PhotoReviewHub');
    this.photoReviewHubProxy.on('updateReceived',
      (id: string, status: number, newNote: string, overlayMetadata: string, exportRotate: number, formattedNotes: string, allNotes: string) => {
        if (this.isParentJobDownloaded(id)) {
          const update: CaptureUpdate = { code: id, status, allNotes, exportRotate, formattedNotes, newNote, overlayMetadata };
          this.zone.run(async () => {
            this.onUpdateReceived$.next(update);
          });
        }
      });

    this.photoReviewHubProxy.on('imageReceived', ((id: string) => {
      if (this.isParentJobDownloaded(id)) {
        this.onImageReceived$.next(id);
      }
    }));

    this.photoReviewHubProxy.on('jobCreated', ((jobRef: string) => {
      console.log('signal r jobCreated response ' + jobRef);
    }));

    this.connection.start(() => {console.log('SignalR connection started...'); })
      .catch((error: Error) => {console.log('SignalR connection error: ', error);})
      .done(() => { });
  }

  private isParentJobDownloaded(code: string): boolean {
    return this.localStorageService.storedJobs.find(jobCode => jobCode.JobFolders[0].Code === code.substring(0, jobCode.JobFolders[0].Code.length)) ? true : false;
  }
}


