<ion-header>

  <ion-toolbar>
    <ion-label slot="end">#{{ captureCount }}</ion-label>
    <ion-buttons slot="end">
      <ion-button class="toolbar-button" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Capture Image</ion-title>
  </ion-toolbar>
</ion-header>


<ion-content>

  <ion-item-group>

    <ion-item lines="none">
      <ion-label>
        <h2 class="header-text">PHOTO NAME</h2>
        <p>{{ photoElement.name }}</p>
      </ion-label>
    </ion-item>

    <ion-item lines="none">
      <ion-label>
        <h2 class="header-text">PHOTO COMMENTS</h2>
        <ng-container *ngIf="photoElement.comments.length > 0; else noComments">
          <p *ngFor="let comment of photoElement.comments">{{ comment }}</p>
        </ng-container>
        <ng-template #noComments>
          <p>Photo has no additional comments.</p>
        </ng-template>
      </ion-label>
    </ion-item>
    <ion-img [src]="image"></ion-img>

    <ng-container *ngIf="!photoElement.isComplete; else photoComplete">

      <ion-item lines="none">
        <ion-button #openCameraButton (click)="openCamera()">
          <ion-icon slot="end" name="camera-outline"></ion-icon>
          {{ takePhotoText }}
        </ion-button>
        <ion-button [disabled]="!canUpload" (click)="upload()">
          <ion-icon slot="end" name="cloud-upload-outline"></ion-icon>
          Accept
        </ion-button>
      </ion-item>

      <ion-item>
        <ion-button #completeSeriesButton *ngIf="photoElement.isSeries" (click)="completeSeries()">
          <ion-icon slot="end" name="checkmark-circle-outline"></ion-icon>
          Complete Series
        </ion-button>
        <ion-button [disabled]="!canUpload || captureNote !== null" (click)="presentAddNoteModal()">
          <ion-icon slot="end" name="chatbox-outline"></ion-icon>
          Add Note
        </ion-button>
      </ion-item>

    </ng-container>

    <ng-template #photoComplete>
      <p class="photo-completed-text">Photo has been completed{{ photoElement.photoCapturesCount === null ? ' with no captures.' : '.'}}</p>
    </ng-template>
  </ion-item-group>

  <ion-item-group>

    <ion-list>
      <ion-item>
        <ion-label>
          <h2 class="header-text">YOUR NOTE </h2>
        </ion-label>
      </ion-item>

      <ion-item lines="none" *ngIf="captureNote">
        <ion-label text-wrap>
          <h2>{{ captureNote.text }}</h2>
        </ion-label>
        <ion-button (click)="deleteNote()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-item>

      <ion-item *ngIf="captureNote === null" lines="none">
        <ion-label text-wrap>
          <p>You have not added a note yet.</p>
        </ion-label>
      </ion-item>

    </ion-list>
  </ion-item-group>

</ion-content>
