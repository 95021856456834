<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button class="toolbar-button" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Add New Job</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-item>
    <ion-input [(ngModel)]="jobCode" label="Enter Code" labelPlacement="floating" type="text" class="custom" fill="solid"></ion-input>
  </ion-item>

  <ion-button (click)="downloadNewJob()" expand="block">
    Download
  </ion-button>

</ion-content>
