import { Injectable } from '@angular/core';
import { Lexicon } from '../models/lexicon';

@Injectable({
  providedIn: 'root'
})
export class LexiconService {
  lexicon: Lexicon;
  constructor() { }

  setNameFromSpecifierValues(specifierValues: string): string {
    let photoName = '';
    const lexiconLookUpValues = specifierValues.split('=');
    //check if the photoset code needs further splitting
    //if so, set subsequent names
    if (lexiconLookUpValues.length > 2) {
      photoName = this.setSubsequentNames(specifierValues);
    }
    else {
      photoName = this.lexicon.LexiconEntrySets
      .find(entrySet => entrySet.Code === lexiconLookUpValues[0]).LexiconEntries
      .find(entry => entry.Code === lexiconLookUpValues[1]).Name;
    }
    return photoName;
  }

  //adds subsequesnt names from the lexicon if name has multiple parts in it
  private setSubsequentNames(specifierValues: string): string{
    let subsequentName = '';

    const lookUpValues = specifierValues.split('&');

    lookUpValues.forEach(v =>{
      const lexiconEntrySetCode = v.split('=')[0];
      const lexiconEntrySetEntryCode = v.split('=')[1];

      if (lookUpValues.indexOf(v) === lookUpValues.length - 1) {
        const name: string = this.lexicon.LexiconEntrySets
        .find(entrySet => entrySet.Code === lexiconEntrySetCode).LexiconEntries
        .find(entry => entry.Code === lexiconEntrySetEntryCode).Name;

        if (name !== 'general' && name !== 'series' && !subsequentName.includes(' ' + name)) {
          subsequentName += ' ' + name;
        };

      }
      else{
        const name = this.lexicon.LexiconEntrySets
        .find(entrySet => entrySet.Code === lexiconEntrySetCode).LexiconEntries
        .find(entry => entry.Code === lexiconEntrySetEntryCode).Name;
        subsequentName += ' ' + name;
      }
    });

    subsequentName = subsequentName.trim();

    return subsequentName;
  }
}
