/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionStatus, Network } from '@capacitor/network';
import { AlertController, ModalController} from '@ionic/angular';
import { FILESYSTEM_JOBS_FOLDER, STORAGE_JOB_CAPTURES } from 'src/app/shared/lookups/consts';
import { FileSystem_JobFolders, Ion_ProgressBar_Type } from 'src/app/shared/lookups/enums';
import { Capture } from 'src/app/shared/models/capture';
import { Job } from 'src/app/shared/models/job';
import { CaptureService } from 'src/app/shared/services/capture.service';
import { JobService } from 'src/app/shared/services/job.service';
import { LoadingOverlayService } from 'src/app/shared/services/loading-overlay.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';



@Component({
  selector: 'app-add-new-job-modal',
  templateUrl: './add-new-job-modal.component.html',
  styleUrls: ['./add-new-job-modal.component.scss'],
})
export class AddNewJobModalComponent implements OnInit {
  jobCode: string;
  loadingOverlay: HTMLIonModalElement;
  jobDownloadedSuccessfully = false;
  downloadedJobCode: string;

  constructor(
    private modalCtrl: ModalController,
    private toastMessageService: ToastMessageService,
    private alertCtrl: AlertController,
    private jobService: JobService,
    private localStorageService: LocalStorageService,
    private captureService: CaptureService,
    private loadingOverlayService: LoadingOverlayService,
    private router: Router,
  ) { }

  ngOnInit() {}

  downloadNewJob(){
    if (!this.jobCode) {
      this.toastMessageService.showToastMessage('\'Job Code\' is a required field.','danger');
    }
    else {
      this.jobCode = this.jobCode.trim().toUpperCase();
      //check if the job is already downloaded
      if (!this.localStorageService.storedJobsReference.find(jobReference => jobReference.toUpperCase() === this.jobCode)) {

       Network.getStatus().then( (result: ConnectionStatus) => {
        if (result.connected) {
          this.startJobDownload();
        } else
        {
          this.toastMessageService.showToastMessage('Network not available','warning');
        }

       });
      } else {
        this.alertCtrl.create({
          message: 'Job Already Downloaded',
          buttons: [{
            text: 'Ok',
            role: 'cancel',
            // handler: () => this.closeModal()
          }]
        }).then(alert => alert.present());

      }
    }
  }

  async closeModal(){
    await this.modalCtrl.dismiss(this.localStorageService.storedJobs.length > 0);
  }

  private startJobDownload() {
     //show overlay
    this.loadingOverlayService.showCustomOverlay(false, .5).then( overlay => {
      overlay.onDidDismiss().then(() => {
        this.modalCtrl.getTop().then(top => {
          top.dismiss(this.jobDownloadedSuccessfully);
        })
      });
      this.loadingOverlay = overlay;
      this.loadingOverlay.present();
    });

    //download job data
    this.loadingOverlayService.currentProcessMessage.next('Downloading job data...');
    this.jobService.getByRecordLocator(this.jobCode).subscribe({
      next: async (job: Job) => {

        this.downloadedJobCode = job.Code;

        this.jobDownloadedSuccessfully = true;
        this.toastMessageService.showToastMessage('Job data downloaded successfully','success',);

        //download job captures data
        this.loadingOverlayService.currentProcessMessage.next('Downloading captures data...');

        this.jobService.getCapturesDataByJobCode(job.Code).subscribe({
          next: async (captures: Capture[]) => {
          this.captureService.capturesToDownload = captures;
          await this.localStorageService.writeToStorage(STORAGE_JOB_CAPTURES + job.Code,captures).then(async () => {
            //save job after the captures are save to make sure the view is not refreshed
            //before we have the captures data saved
            await this.localStorageService.saveJob(job);
            //update stored jobs captures object
            this.localStorageService.storedJobCaptures$.next({jobCode: job.Code, jobFolderCode: job.JobFolders[0].Code, captures});
          });

          await this.toastMessageService.showToastMessage('Captures data downloaded successfully','success');

          const itemDownloadedSubscription = this.captureService.itemDownloadedSubject.subscribe(async () => {
            const progressValue = (this.captureService.captureDownloadIndex + 1)/this.captureService.capturesToDownload.length;

            //update download progress
            this.loadingOverlayService.currentProgressValue.next(progressValue);
          }
          );

          const busyDownloadingSubscription = this.captureService.isBusyDownloadingThumbnailsSubject.subscribe((isBusy: boolean)=>{

            this.alertCtrl.create({
              header: 'Job Downloaded',
              message: 'Would you like to open the job now?',
              cssClass: 'alert-main-container',
              buttons: [
                {
                  text: 'No',
                  role: 'cancel',
                  cssClass: 'alert-cancel-button'
                },
                {
                  text: 'Yes',
                  cssClass: 'alert-confirm-button',
                  handler: () => this.redirectToDownloadedJob()
                }
              ]
            }).then(alert => {
              alert.present();
              alert.onWillDismiss().then(() => {
                this.dismissAllOverlays();
                busyDownloadingSubscription.unsubscribe();
                itemDownloadedSubscription.unsubscribe();
              });
            });

          });

          // create an outbox directory to keep track of any failed uploads
          const filePath = `${FILESYSTEM_JOBS_FOLDER}/${job.JobFolders[0].Code}/${FileSystem_JobFolders.Outbox}`;
          this.localStorageService.createDirectoryInFilesystem(filePath);

          //download the thumbnails if we have any captures
          if (captures.length > 0) {
            //download job thumbnails one by one
            this.loadingOverlayService.currentProcessMessage.next('Downloading thumbnails...');
            this.loadingOverlayService.progressBarType.next(Ion_ProgressBar_Type.Determinate);
            this.loadingOverlayService.showPercentage.next(true);
            this.captureService.downloadCapturesThumbnails(job.Code, job.JobFolders[0].Code);
          }
          else {
            this.captureService.isBusyDownloadingThumbnailsSubject.next(false);
          }

        },
        error: (error: Error) => {
          this.toastMessageService.showToastMessage(error.message);
          this.loadingOverlayService.dismissCustomOverlay();
          this.modalCtrl.dismiss();
          this.loadingOverlayService.setDefaults();
        }
      });

      },
      error: (error: Error) => {
        this.toastMessageService.showToastMessage(error.message);
        this.loadingOverlayService.dismissCustomOverlay();
        this.modalCtrl.dismiss();
        this.loadingOverlayService.setDefaults();
      }
    });
  }

  private async redirectToDownloadedJob(){
    this.dismissAllOverlays();
    this.router.navigate([`/jobs/${this.downloadedJobCode}/overview`]);
  }

  private async dismissAllOverlays(){
      await this.alertCtrl.dismiss();
      await this.loadingOverlay.dismiss();
      this.loadingOverlayService.setDefaults();
  }

}
