/* eslint-disable max-len */
import { Component, EventEmitter, Input, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertController, AlertInput, ModalController } from '@ionic/angular';
import { SwiperComponent } from 'swiper/angular';
import { FullSizeCaptureElement } from 'src/app/shared/models/full-size-capture-element';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { FileSystem_JobFolders, Filesystem_JobFolder_PhotosFolders, SimpleStatus } from 'src/app/shared/lookups/enums';
import { BASE64_IMAGE_PREFIX, FILESYSTEM_JOBS_FOLDER } from 'src/app/shared/lookups/consts';
import { LoadingOverlayService } from 'src/app/shared/services/loading-overlay.service';
import { CaptureService } from 'src/app/shared/services/capture.service';
import { Note } from 'src/app/shared/models/note';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';
import { TakePhotoModalComponent } from '../take-photo-modal/take-photo-modal.component';
import { PhotoElement } from 'src/app/shared/models/photo-element';
import { PhotosPageService } from 'src/app/shared/services/photos-page.service';
import { BehaviorSubject } from 'rxjs';
import { BlobFile } from 'src/app/shared/models/CustomFiles';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Camera, CameraSource, CameraResultType, Photo } from '@capacitor/camera';
import { SignalRHubService } from 'src/app/shared/services/signal-r-hub.service';
import { CaptureUpdate } from 'src/app/shared/models/capture-update';
import { APPGeoLocationService } from 'src/app/shared/services/app.location.service';
import { JobService } from 'src/app/shared/services/job.service';


SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-photo-modal',
  templateUrl: './photo-modal.component.html',
  styleUrls: ['./photo-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PhotoModalComponent implements OnInit {

  @ViewChild('swiper', ) swiper: SwiperComponent ;
  @Input() fullSizeCaptureElements: FullSizeCaptureElement[];
  @Input() photoElement: PhotoElement;

  isPhotoAccepted$ = new BehaviorSubject<boolean>(false);
  newThumbnailData: string;

  swiperConfig: SwiperOptions = {
    pagination: false
  };

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  hardcodedSvg: string = `<svg xmlns='http://www.w3.org/2000/svg' style='position:absolute;top:0px;left:0px;width:640px;height:480px;z-index:1000'>
  <path fill='none' stroke='red' stroke-width='3' d='M 111 167 L 93 357 L 103 335 M 93 357 L 87 334'/>
  <g>
    <rect fill='black' stroke='red' stroke-width='3' x='52' y='158' width='118' height='18' rx='5' ry='5'/>
    <text text-anchor='middle' font-family='Arial' font-size='12' fill='white' x='111' y='167' dy='.4em'>arm in the picture</text>
  </g>
  </svg>`;

  trustedHTML: SafeHtml;
  //keeps tract of the swiper index
  currentIndex = 1;

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private localStorageService: LocalStorageService,
    private overlayService: LoadingOverlayService,
    private captureService: CaptureService,
    private jobService: JobService,
    private signalRHubService: SignalRHubService,
    private toastMessageService: ToastMessageService,
    private photosPageService: PhotosPageService,
    private locationService: APPGeoLocationService,
    private zone: NgZone,
    private sanirizer: DomSanitizer
  ) {
    this.signalRHubService.onUpdateReceived$.subscribe((captureUpdate: CaptureUpdate) => {
      const elementToUpdate = this.fullSizeCaptureElements.find(e => e.captureCode === captureUpdate.code);

      if (elementToUpdate) {
        this.photosPageService.updateFullSizeCaptureElementFromCaptureUpdate(captureUpdate, elementToUpdate);
      }
    });

    this.trustedHTML = this.sanirizer.bypassSecurityTrustHtml(this.hardcodedSvg);

    this.photosPageService.updatedPhotoElement$.subscribe(pe => {
      if (pe.photoCode === this.photoElement.photoCode) {
        this.isPhotoAccepted$.next(pe.currentStatus === SimpleStatus.Accepted);
      }
    });
  }

  ngOnInit() {
    
    
    // reset the new thumbnail data
    this.newThumbnailData = null;
    this.readFullSizeCapturesFromStorage()
    .catch((error) => {
      this.toastMessageService.showToastMessage('Something went wrong while reading the device storage.');
    });

    // disable the button once the capture for the photo is accepted
    this.isPhotoAccepted$.next(this.photoElement.currentStatus === SimpleStatus.Accepted);

    console.log("this.fullSizeCaptureElements",this.fullSizeCaptureElements);
  }

  async openCamera(){
    if (await this.locationService.isLocationServiceOn()) {
      this.locationService.getCurrentUserLocation();
      const takenPhoto = await Camera.getPhoto({
        source: CameraSource.Camera,
        allowEditing: false,
        quality: 70,
        resultType: CameraResultType.Base64
      }).catch((error: Error) => console.log(error.message));

      if (takenPhoto) {
        this.showTakePhotoModal(takenPhoto);
      }
    } else {
      this.toastMessageService.showToastMessage('Please turn on the location.', 'warning');
    }
  }

  async selectFromGallery(){
    if (await this.locationService.isLocationServiceOn()) {
      this.locationService.getCurrentUserLocation();
      //display device photos
      const gallerySelectedPhoto = await Camera.getPhoto({
        source: CameraSource.Photos,
        allowEditing: false,
        quality: 70,
        resultType: CameraResultType.Base64
      }).catch((error: Error) => console.log(error.message));

      if (gallerySelectedPhoto) {
        this.showTakePhotoModal(gallerySelectedPhoto);
      }
    } else {
      this.toastMessageService.showToastMessage('Please turn on the location.', 'warning');
    }
  }

  async closeModal(){
    //when a new slide is added swiper needs to be updated.
    this.swiper.swiperRef.update();
    await this.modalCtrl.dismiss(this.newThumbnailData);
  }

  async presentAddNoteModal(){
    const currentElement: FullSizeCaptureElement = this.fullSizeCaptureElements[this.swiper.swiperRef.activeIndex];
    const alert = await this.alertCtrl.create({
      header: 'Add Note',
      cssClass: 'alert-main-container',
      inputs: [
        {
          name: 'note',
          type: 'text',
          cssClass: 'alert-input',
          placeholder: 'Enter Note'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'alert-cancel-button'
        },
        {
          text: 'Add Note',
          cssClass: 'alert-confirm-button',
          handler: (inputs: AlertInput) => {this.addNote(inputs, currentElement);}
        }
      ]
    });

    await alert.present().then(() => {
      //set focus on the input field
      const firstInput: any = document.querySelector('ion-alert input');
      firstInput.focus();
      return;
    });
  }

  prevSlide(){
    this.swiper.swiperRef.slidePrev();
  }

  nextSlide(){
    this.swiper.swiperRef.slideNext();
  }

  nextIndex(){
    this.zone.run(async () => {
      this.currentIndex = this.currentIndex + 1;
    });
  }

  prevIndex(){
    this.zone.run(async () => {
      this.currentIndex = this.currentIndex - 1;
    });
  }

  activeIndexChange(event){
    this.zone.run(async () => {
      //full size element after the transition has been complete
      const element = this.fullSizeCaptureElements[event[0].activeIndex];
      if (!element.isFullSizeDownloaded) {
        await this.overlayService.showLoadingOverlay('Downloading full size capture...').then(overlay => {
          overlay.present().then(async () => {
            this.captureService.downloadCaptureAsync(Filesystem_JobFolder_PhotosFolders.LoRes, element.jobFolderCode, element.fileName)
            .subscribe({
              next: (file: BlobFile) => {
                const filePath = `${FILESYSTEM_JOBS_FOLDER}/${element.jobFolderCode}/${FileSystem_JobFolders.Photos}/${Filesystem_JobFolder_PhotosFolders.LoRes}/${file.name}`;
                this.localStorageService.saveBlobToFilesystem(file.data, filePath)
                .then( () => {
                  this.localStorageService.readImageFileData(Filesystem_JobFolder_PhotosFolders.LoRes, element.jobFolderCode, element.fileName)
                  .then(f => {
                    element.fullSizeCaptureData = BASE64_IMAGE_PREFIX + f.data;
                    element.isFullSizeDownloaded = true;
                    this.overlayService.dismissLoadingOverlay();
                  });
                })
                .catch((error: Error) => {
                  this.toastMessageService.showToastMessage('Something went wrong when saving the image.', 'danger');
                });
              },
              error: (error: Error) =>{
                this.toastMessageService.showToastMessage('Something went wrong when downloading the image.');
                overlay.dismiss();
              }
            });
          });
        });
      }
    });
  }

  private async addNote(inputs: any, element: FullSizeCaptureElement){
    const note: Note = {
      text: inputs.note,
      author: 'appuser', //TODO: needs a discussion what should be used as an author eg. users name, phone model etc.
      date: Date.now(),
    };

    //add the note to the current element
    await this.jobService.saveNote(note, element.captureCode).then(()=> {
      element.notes.unshift(note);
    }).catch((error: Error) => {
      console.log(error.message);
      this.toastMessageService.showToastMessage(error.message);
    });
  }

  private async showTakePhotoModal(photo: Photo = null){
   //subscribe to new full size element event emitter
   const eventEmitter= new EventEmitter<FullSizeCaptureElement>();
   eventEmitter.subscribe((newFullSizeElement: FullSizeCaptureElement)=>{
     this.addNewFullSizeElement(newFullSizeElement);
   });

   //show take photo modal
   await this.modalCtrl.create({
     component: TakePhotoModalComponent,
     componentProps: {
       photoElement: this.photoElement,
       inputPhoto: photo,
       newFullSizeElement: eventEmitter
     }
   }).then(modal =>  {
     modal.onDidDismiss().then((imageData) => {
       if (imageData.data) {
        this.newThumbnailData = imageData.data;
       }
     });

     modal.present();
   });
  }

  private addNewFullSizeElement(newFullSizeElement: FullSizeCaptureElement) {
    this.fullSizeCaptureElements.unshift(newFullSizeElement);
    this.swiper.swiperRef.update();
  }

  private async readFullSizeCapturesFromStorage(): Promise<void> {
    const promises: Promise<void>[] = [];

    this.fullSizeCaptureElements.forEach((fse) => {
      if (fse.isFullSizeDownloaded) {
        const promise = this.localStorageService.readImageFileData(Filesystem_JobFolder_PhotosFolders.LoRes, fse.jobFolderCode, fse.fileName)
          .then((file) => {
            fse.fullSizeCaptureData = BASE64_IMAGE_PREFIX + file.data;
          });

        promises.push(promise);
      }
    });

    await Promise.all(promises);
  }
}
