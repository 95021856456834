import { Component, Input, OnInit } from '@angular/core';
import { Ion_ProgressBar_Type } from 'src/app/shared/lookups/enums';
import { LoadingOverlayService } from 'src/app/shared/services/loading-overlay.service';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent implements OnInit {
  message: string;
  progress: number;
  showPercentage: boolean;
  progressBarType: Ion_ProgressBar_Type;
  percentage: string;

  constructor(private loadingOverlayService: LoadingOverlayService) {
    this.loadingOverlayService.currentProcessMessage.subscribe(message => this.message = message);
    this.loadingOverlayService.currentProgressValue.subscribe(progress => {
      this.progress = progress;
      this.percentage = (Math.round(progress * 10000) / 100).toFixed(0);
    });
    this.loadingOverlayService.showPercentage.subscribe(showPercentage => this.showPercentage = showPercentage);
    this.loadingOverlayService.progressBarType.subscribe(progressBarType => this.progressBarType = progressBarType);
   }

   ngOnInit() {}

}
