import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { JobDetailsOverviewPageRoutingModule } from './job-details-overview-routing.module';
import { environment } from '../../../environments/environment';
import { JobDetailsOverviewPage } from './job-details-overview.page';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    JobDetailsOverviewPageRoutingModule
  ],
  declarations: [JobDetailsOverviewPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class JobDetailsOverviewPageModule {}
