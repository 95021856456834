import { Component, NgZone, OnInit } from '@angular/core';
import { STATUS_ICONS } from '../../lookups/consts';
import { Color, PhotoFiltersText, SimpleStatus } from '../../lookups/enums';
import { Filter } from '../../models/filter';
import { PhotoLogSectionElement } from '../../models/photo-log-section-element';
import { PhotoFilterService } from '../../services/photo-filter.service';
import { PhotosPageService } from '../../services/photos-page.service';
import { LoadingOverlayService } from '../../services/loading-overlay.service';

@Component({
  selector: 'app-photo-filter',
  templateUrl: './photo-filter.component.html',
  styleUrls: ['./photo-filter.component.scss'],
})
export class PhotoFilterComponent implements OnInit {

  photoLogSectionElements: PhotoLogSectionElement[];

  filters: Filter[] = [
    {
      displayText: PhotoFiltersText.Submitted,
      color: Color.Submitted,
      icon: STATUS_ICONS.Submitted,
      isApplied: false,
      value: SimpleStatus.Submitted,
      itemCount: 0
     },
     {
       displayText: PhotoFiltersText.ToDo,
       color: Color.Warning,
       icon: STATUS_ICONS.Awaiting,
       isApplied: false,
       value: SimpleStatus.Awaiting,
       itemCount: 0
     },
     {
       displayText: PhotoFiltersText.Attention,
       color: Color.Danger,
       icon: STATUS_ICONS.Attention,
       isApplied: false,
       value: SimpleStatus.Attention,
       itemCount: 0
     },
     {
       displayText: PhotoFiltersText.Accepted,
       color: Color.Success,
       icon: STATUS_ICONS.Accepted,
       isApplied: false,
       value: SimpleStatus.Accepted,
       itemCount: 0
     },
     {
     displayText: PhotoFiltersText.Flagged,
       color: Color.Warning,
       icon: STATUS_ICONS.Flagged,
       isApplied: false,
       value: SimpleStatus.Flagged,
       itemCount: 0
     },
     {
     displayText: PhotoFiltersText.Rejected,
       color: Color.Danger,
       icon: STATUS_ICONS.Rejected,
       isApplied: false,
       value: SimpleStatus.Rejected,
       itemCount: 0
     }
   ];

  constructor(
    private photosPageService: PhotosPageService,
    private photoFilterService: PhotoFilterService,
    private loadingOverlayService: LoadingOverlayService,
    private zone: NgZone
    ) {
      this.zone.run(async ()=> {

        this.photosPageService.photoLogSectionElementsSubject.subscribe(elements => {
          this.photoLogSectionElements = elements;
          this.getFiltersValues();
        });

        this.photoLogSectionElements = this.photosPageService.photoLogSectionElements;
        this.getFiltersValues();
      });
  }

  ngOnInit() {
    this.photoFilterService.showFilterLoadingOverlay$.subscribe(isLoadingFilteredData => {
      // hide the loading overlay for filtered data
      if (!isLoadingFilteredData){
        this.loadingOverlayService.dismissLoadingOverlay();
      }
    });
   }

   applyFilter(filter: Filter) {
     this.loadingOverlayService.showLoadingOverlay('Loading...').then(async overlay => {
      await overlay.present();
      
      if (filter.isApplied) {
        filter.isApplied = false;
        this.photoFilterService.removePhotoFilter(filter);
      } else {
        filter.isApplied = true;
        this.photoFilterService.addPhotoFilter(filter);
      }
    });
  }

  private getFiltersValues() {
    this.zone.run(async ()=> {
      for (const filter of this.filters) {
        filter.itemCount = this.getFilterItemCount(filter.value);

      }
    });
  }

  private getFilterItemCount(filter: SimpleStatus): number{
    let filterItemCount = 0;
    this.photoLogSectionElements.forEach(pls => {
      pls.photoSetElements.forEach(pse =>

        filterItemCount += pse.photoElements.filter(pe => pe.currentStatus === filter).length);
      });

    return filterItemCount;
  }
}
