import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { AddNewJobModalComponent } from './components/add-new-job-modal/add-new-job-modal.component';
import { PhotoModalComponent } from './components/photo-modal/photo-modal.component';
import { SwiperModule } from 'swiper/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppInterceptorService } from './shared/services/app-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { IonicStorageModule } from '@ionic/storage-angular';



@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent,
    AddNewJobModalComponent,
    PhotoModalComponent
  ],
  imports: [
    BrowserModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    RouterModule,
    SwiperModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    FontAwesomeModule,
    // AgmCoreModule.forRoot({
    //   apiKey: environment.googleMapsAPIKey
    // })
  ],
  providers: [

    {
      provide:
      HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }

  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
