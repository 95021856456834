/* eslint-disable @typescript-eslint/naming-convention */
import { faCircleCheck, faClock, faImage, faImages } from '@fortawesome/free-regular-svg-icons';
import { faCircleExclamation, faExclamation, faThumbsDown, faThumbsUp, faFlag } from '@fortawesome/free-solid-svg-icons';

//local storage keys
export const STORAGE_MY_LOCATION = 'MyLocations';
export const STORAGE_MY_CAN_GET_LOCATION = 'CanGetLocation';

export const STORAGE_MY_JOBS = 'MyJobs';
export const STORAGE_MY_JOBS_REFERENCE = 'MyJobsReference';
export const STORAGE_JOB_CAPTURES = 'Captures/Job_'; //add the job code after the underscore when constructing the starage key
export const STORAGE_MY_SETTINGS = 'MySettings';
export const STORAGE_REGISTER_SETTINGS = 'RegisterSettings';
export const STORAGE_ABOUT_PAGE_SETTINGS = 'AboutPageSettings';
export const STORAGE_IS_DEVICE_REGISTERED = 'IsDeviceRegistered';
export const STORAGE_APP_HAD_INITIAL_LAUNCH = 'AppHadInitialLaunch';
export const STORAGE_THUMBNAIL_FAILED_DOWNLOADS = 'ThumbnailFailedDownloads';
export const STORAGE_SITE_URL = 'SiteUrl';
export const FILESYSTEM_JOBS_FOLDER = 'Filestore/jobs';
export const AWAITING_IMAGE_PATH = '/assets/img/image-awaiting.png';

//other
export const BASE64_IMAGE_PREFIX = 'data:image/jpeg;base64,';

//filter icons
export const STATUS_ICONS = {
  Submitted: faCircleCheck,
  Awaiting: faClock,
  Attention: faExclamation,
  Accepted: faThumbsUp,
  Flagged: faFlag,
  Rejected: faThumbsDown,
  IsSeries: faImages,
  IsPriority: faCircleExclamation,
  Camera: faImage
};
