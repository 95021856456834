import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faHourglassEmpty, faHourglassStart, faHourglassEnd, faClock, faSquareCheck } from '@fortawesome/free-solid-svg-icons';


@Pipe({
  name: 'fontAwesome'
})
export class FontAwesomePipe implements PipeTransform {

  transform(jobStatus: number): IconDefinition {

    switch (jobStatus) {
      case 0: //created
        return faHourglassEmpty;
      case 1: //ready
       return faSquareCheck;
      case 2: //started
       return faHourglassStart;
      case 3: //paused
        return faClock;
      case 4: //completed
       return faHourglassEnd;
      default:
        return faHourglassEmpty;
    }
  }

}
