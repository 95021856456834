import { Injectable } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { BehaviorSubject} from 'rxjs';
import { LoadingOverlayComponent } from 'src/app/components/loading-overlay/loading-overlay.component';
import { Ion_ProgressBar_Type } from '../lookups/enums';

@Injectable({
  providedIn: 'root'
})
export class LoadingOverlayService {

  currentProgressValue = new BehaviorSubject<number>(0);
  currentProcessMessage = new BehaviorSubject<string>('Please wait...');
  showPercentage = new BehaviorSubject<boolean>(false);
  progressBarType = new BehaviorSubject<Ion_ProgressBar_Type>(Ion_ProgressBar_Type.Indeterminate);

  // to keep track of number of operation progress value
   progressValue = 0;

  constructor(
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController
  ) { }

  async showLoadingOverlay(message: string, duration: number = 0){
    return  await this.loadingCtrl.create({
      message,
      duration
    });
  }

  async dismissLoadingOverlay(){
    await this.loadingCtrl.getTop().then(top => {
      if (top) {
         this.loadingCtrl.dismiss();
      }
    });
  }

  async showCustomOverlay(backdropDismiss: boolean = false, initialBreakpoint: number = 1){
    return await this.modalCtrl.create({
      component: LoadingOverlayComponent,
      componentProps: {},
      cssClass: 'transparent-overlay',
      backdropDismiss,
      initialBreakpoint
    });
  }

  async dismissCustomOverlay(dataToPassUponDismission: any = false){
    await this.modalCtrl.getTop().then(top => {
      if (top) {
         this.modalCtrl.dismiss(dataToPassUponDismission);
      }
    });
  }

  setDefaults() {
    this.currentProcessMessage.next('Please wait...');
    this.showPercentage.next(false);
    this.progressBarType.next(Ion_ProgressBar_Type.Indeterminate);
    this.currentProgressValue.next(0);
  }
}
