import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class AppInterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


      request = request.clone({ headers: request.headers.set("Access-Control-Allow-Origin", window.location.origin) });
      request = request.clone({ headers: request.headers.set('withCredentials', 'true') });
      request = request.clone({ headers: request.headers.set('Referrer-Policy', 'origin') });

      console.log("Request Interceptor", request)

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
        }
        return event;
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            console.log('UnAuthorized user', err);

          }
        }
      }));

  }
}

