import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { SplashScreen } from '@capacitor/splash-screen';
import { AppSideMenuItem } from './shared/models/app-side-menu-item';
import { Network, ConnectionStatus } from '@capacitor/network';
import { LocalStorageService } from './shared/services/local-storage.service';
import { ToastMessageService } from './shared/services/toast-message.service';
import { LoadingOverlayService } from './shared/services/loading-overlay.service';
import { NetworkService } from './shared/services/network.service';
import { ConnectedIconColor } from './shared/lookups/enums';
import { JobService } from './shared/services/job.service';
import { BehaviorSubject } from 'rxjs';
import { StatusBar } from '@capacitor/status-bar';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isMenuVisible = false;
  isNetworkConnected$ = new BehaviorSubject<boolean>(false);

  public mainMenuItems: AppSideMenuItem[] = [
    { title: 'Jobs', url: '/jobs', icon: 'construct' },
    { title: 'About', url: '/about', icon: 'information-circle' }
  ];

  public accountMenuItems: AppSideMenuItem[] = [
    // { title: 'Register', url: '/register', icon: 'person-add-outline' },
    { title: 'Settings', url: '/settings', icon: 'settings' },
  ];

  public helpMenuItems: AppSideMenuItem[] = [
    { title: 'Support', url: '/support', icon: 'help-circle' },
  ];

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private overlayService: LoadingOverlayService,
    private jobService: JobService,
    private networkService: NetworkService
  ){
    StatusBar.setBackgroundColor({ color: '#212652' }).catch(() => { });
    Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
      const hasConnectedToNetwork = status.connected && status.connected != this.isNetworkConnected$.value;

      if (hasConnectedToNetwork) {
        this.toastMessageService.showToastMessage('Checking for pending uploads/downloads.', 'medium');
        this.jobService.checkForPendingUploadsAndDownloads();
      }

      this.setConnectedIconColor(status);

      this.isNetworkConnected$.next(status.connected);

    });

    this.localStorageService.siteUrl$.subscribe(value => {
      if (value) {
        this.isMenuVisible = true;
        this.localStorageService.appHadInitialLaunch = true;
      }
    });

  
  }

  async ngOnInit() {
    await Network.getStatus().then((status: ConnectionStatus) => {
      this.setConnectedIconColor(status);
      this.isNetworkConnected$.next(status.connected);
    });

    // SplashScreen.show({
    //   autoHide: true
    // });

    await this.localStorageService.init();

    const isSiteUrlProvided: boolean = this.localStorageService.siteUrl$.value? true : false;
    // redirect to the settings so the user can set the site url
    if (!isSiteUrlProvided) {
      this.router.navigate(['/settings']);
    } else {

      // redirect to jobs page if we have a site url
      this.router.navigate(['/jobs'])
      .then(() => {
        this.isMenuVisible = true;
      });
    }

    if (this.isNetworkConnected$.value) {
      // await this.synchronizeJobData();
    }
    else {
      this.toastMessageService.showToastMessage('To check for the job(s) updates, please connect to a network.', 'warning', 5000);
    }
  }

  private async synchronizeJobData() {
    if (this.localStorageService.storedJobsReference.length > 0) {
      const jobsUpdateOverlay = await this.overlayService.showLoadingOverlay('Checking for job(s) updates');
      jobsUpdateOverlay.present();

      await this.jobService.updateJobData().then(() => {
        jobsUpdateOverlay.dismiss();
      })
      .catch((error: Error) => {
        this.toastMessageService.showToastMessage('Something went wrong when updating the job(s).', 'warning');
      });

      this.toastMessageService.showToastMessage('The job(s) are up to date.', 'success');
    }
  }

  private setConnectedIconColor(status: ConnectionStatus) {
    if (status.connected) {
      this.networkService.isConnectedIconColor$.next(ConnectedIconColor.Success);
    } else {
      this.networkService.isConnectedIconColor$.next(ConnectedIconColor.Danger);
    }
  }
}
