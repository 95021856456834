import { Injectable } from "@angular/core";
import { STORAGE_MY_LOCATION } from "../lookups/consts";
import { LocalStorageService } from "./local-storage.service";
import { Geolocation, Position } from '@capacitor/geolocation';



@Injectable({
  providedIn: 'root',
})
export class APPGeoLocationService {
  constructor(
    private localStorageService: LocalStorageService
  ) { }

  async isLocationServiceOn(): Promise<boolean> {
    try {
      const permissions = await Geolocation.checkPermissions();
      return true;
    } catch (error) {
      return false;
    }
  }

  async getCurrentUserLocation() {
    await Geolocation.getCurrentPosition()
    .then(async dataLocation => {
      if (dataLocation) {
        var geoLocationFormated = this.formatGeoObject(dataLocation);
        await this.localStorageService.writeToStorage(STORAGE_MY_LOCATION, geoLocationFormated);
      }
    })
    .catch( (error: Error) => {console.log(error); });
  }

  // the capacitor geo object cannot be stored directly in the IndexedDB, it needs to be formatted
  private formatGeoObject(location: Position) {
    return {
      timestamp: location.timestamp,
      coords: {
        accuracy: location.coords.accuracy,
        altitude: location.coords.altitude,
        altitudeAccuracy: location.coords.altitudeAccuracy,
        heading: location.coords.heading,
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        speed: location.coords.speed
      }
    }
  }


}

