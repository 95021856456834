/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


import { FILESYSTEM_JOBS_FOLDER, STORAGE_THUMBNAIL_FAILED_DOWNLOADS } from '../lookups/consts';
import { BlobFileType, FileSystem_JobFolders, Filesystem_JobFolder_PhotosFolders } from '../lookups/enums';
import { Capture } from '../models/capture';
import { BlobFile } from '../models/CustomFiles';
import { Note } from '../models/note';
import { LocalStorageService } from './local-storage.service';
import { FailedDownload } from '../lookups/types';
import { FileInfo } from '@capacitor/filesystem';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root',
})
export class CaptureService {

  capturesToDownload: Capture[] = [];
  captureDownloadIndex = 0;

  //a newly created capture
  newCapture$ = new Subject<Capture>();

  //to track the if the item has been downloaded successfully
  itemDownloadedSubject = new Subject<boolean>();

  //to track if the entire download operation is complete ei. went through all the captures
  isBusyDownloadingThumbnailsSubject = new Subject<boolean>();

  private urlPrefix = '';
  private captureUpdateUrl = '';
  private captureUploadUrl = '';
  private thumbnailsFailedDownloads: FailedDownload[] = [];

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private fileService: FileService,
  ) {
    this.localStorageService.siteUrl$.subscribe(res => {
      this.urlPrefix = res;
      this.setURLs();
    });
  }

  async downloadCapturesThumbnails(jobCode: string, jobFolderCode: string) {
    const thumbnailPath = this.urlPrefix + `/Content/Jobs/${jobFolderCode}/Photos/TileLg/` + this.capturesToDownload[this.captureDownloadIndex].FileName;

    this.http
      .get(thumbnailPath, { responseType: 'blob' })
      .pipe(
        catchError(this.handleError),
        map((blob: Blob) => {
          const file: BlobFile = {
            name: this.capturesToDownload[this.captureDownloadIndex].FileName,
            data: blob,
          };
          return file;
        })
      )
      .subscribe({
        next: async (file: BlobFile) => {
          this.itemDownloadedSubject.next(true);
          // path to save the blob to
          const filePath = `${FILESYSTEM_JOBS_FOLDER}/${jobFolderCode}/${FileSystem_JobFolders.Photos}/${Filesystem_JobFolder_PhotosFolders.TileLg}/${file.name}`;
          await this.localStorageService.saveBlobToFilesystem(file.data, filePath)
          .catch((error: Error) => {
            console.log('Something went wrong when saving the image.');
          });
          this.captureDownloadIndex++;

          if (this.capturesToDownload.length > this.captureDownloadIndex) {
            this.downloadCapturesThumbnails(jobCode, jobFolderCode);
          } else {
            this.isBusyDownloadingThumbnailsSubject.next(false);
            this.captureDownloadIndex = 0;
          }
        },
        error: (error: Error) => {
          console.log(error);
          const failedDownload: FailedDownload = {jobCode, jobFolderCode, downloadPath: thumbnailPath};
          this.thumbnailsFailedDownloads.push(failedDownload);

          this.captureDownloadIndex++;
          if (this.capturesToDownload.length > this.captureDownloadIndex) {
            this.downloadCapturesThumbnails(jobCode, jobFolderCode);
          } else {
            this.saveCapturesFailedThumbnailDownloads(this.thumbnailsFailedDownloads);

            this.isBusyDownloadingThumbnailsSubject.next(false);
            this.captureDownloadIndex = 0;
          }
          this.itemDownloadedSubject.next(false);
        }
    });
  }

  async downloadCaptureThumbnail(jobCode: string, jobFolderCode: string, fileName: string) {
    const thumbnailPath = this.urlPrefix + `/Content/Jobs/${jobFolderCode}/Photos/TileLg/` + fileName;

    return firstValueFrom(this.http.get(thumbnailPath, { responseType: 'blob' })
    .pipe(
      catchError(this.handleError),
      map((blob: Blob) => {
        const file: BlobFile = {
          name: fileName,
          data: blob,
        };
        return file;
      })
    ));
  }

  downloadCaptureAsync(size: Filesystem_JobFolder_PhotosFolders, jobFolderCode: string, fileName: string) {
    const downloadPath = this.urlPrefix + `/Content/Jobs/${jobFolderCode}/Photos/${size}/` + fileName;

    return this.http.get(downloadPath, { responseType: 'blob' }).pipe(catchError(this.handleError),
    map((blob: Blob) => {
      const file: BlobFile = {
          name: fileName,
          data: blob,
        };
        return file;
      })
    );
  }

  async downloadThumbnailsByFilePath(failedDownloads: FailedDownload[]) {
    const successfulDownloads = [];

    for (const failedDownload of failedDownloads) {

      await this.downloadThumbnailByPath(failedDownload).toPromise().then(async (file: BlobFile) => {
        // path to save the blob to
        const filePath = `${FILESYSTEM_JOBS_FOLDER}/${failedDownload.jobFolderCode}/${FileSystem_JobFolders.Photos}/${Filesystem_JobFolder_PhotosFolders.TileLg}/${file.name}`;
        await this.localStorageService.saveBlobToFilesystem(file.data, filePath).then(() => {
          successfulDownloads.push(failedDownload);
        })
        .catch((error: Error) => {
          console.log('Something went wrong when saving the image.', error.message);
        });
      }, (error: Error) => {
        console.log(error);
      });
    }

    if (successfulDownloads.length > 0) {
      // update the failed downloads array
      const updatedFailedDownloads = failedDownloads.filter(item => !successfulDownloads.includes(item));
      this.localStorageService.writeToStorage(STORAGE_THUMBNAIL_FAILED_DOWNLOADS, updatedFailedDownloads);
    }
  }

  downloadThumbnailByPath(failedDownload: FailedDownload): Observable<BlobFile> {
    return this.http.get(failedDownload.downloadPath, { responseType: 'blob' }).pipe(catchError(this.handleError),
    map((blob: Blob) => {
      const fileName = failedDownload.downloadPath.substring(failedDownload.downloadPath.lastIndexOf('/') + 1);

      const file: BlobFile = {
          name: fileName,
          data: blob,
        };

        return file;
      })
      );
  }

  getCapturesByJobCode(jobCode: String){
    const downloadPath = this.urlPrefix + `/jobs/appgetcaptures/${jobCode}`;

    return this.http.get(downloadPath).pipe(catchError(this.handleError));
  }

  async uploadFilesByFileInfo(filesArray: FileInfo[]): Promise<void> {

    // Map each file info object to a promise of its upload process
    const uploadPromises = filesArray.map(item => {
      return new Promise((resolve, reject) => {
        const fileType = item.name.substring(item.name.lastIndexOf('.')) === '.xml' ? BlobFileType.XML : BlobFileType.Image;

        // Get file data from the storage
        this.localStorageService.readFileData(item.uri)
        .then(async fileData => {
          // Convert file to blob
          const fileBlob = this.fileService.createFileBlobFromBase64String(fileData.data as string, fileType);

          // Upload the file
          return await this.uploadFile(fileBlob, item.name)
          .catch(error => console.log(error.message)
          );
        })
        .then(() => {
          // Remove successfully uploaded file from the outbox
          return this.localStorageService.deleteFileFromFilesystem(item.uri);
        })
        .then(resolve) // Resolve the outer promise when the file is uploaded and removed
        .catch((error: Error) => {
            reject(error); // Reject the outer promise if any step fails
        });
      });
    });

    // Wait for all file upload promises to complete
    await Promise.all(uploadPromises).catch(error => {
        console.error('One or more file uploads failed', error);
    });
}


  async uploadFile(file: Blob, fileName: string) {
    const postBody = new FormData();
    postBody.append('file', file, fileName);

    return await firstValueFrom(this.http.post<any>(`${this.captureUploadUrl}/${fileName}`, postBody).pipe(catchError(this.handleError)));
  }

  postCaptureUpdateAsync(note: Note, captureCode: string) {
    console.log("New note",note)
    const postBody = new FormData();
    postBody.set('id', captureCode);
    postBody.set('newNote', note.text);
    postBody.set('overlayMetadata', note.overlay);
    postBody.set('newPhotoCode', '');

    return this.http.post<any>(`${this.captureUpdateUrl}`, postBody).pipe(catchError(this.handleError));
  }

  // converts a note object into a note string in a format that is handled by the server
  createNoteString(note: Note): string{
    let noteString = `${note.text}<by:${note.author};date:${note.date};status:${note.status}>`;
    return noteString;
  }

  // stores captures failed downloads
  async saveCapturesFailedThumbnailDownloads(failedDownloads: FailedDownload[]) {
    // Get existing failed downloads from storage
    const existingFailedDownloads = await this.localStorageService.getFromStorage(STORAGE_THUMBNAIL_FAILED_DOWNLOADS) || [];

    if (existingFailedDownloads.length === 0) {
      // write the failed downloads to the storage
      this.localStorageService.writeToStorage(STORAGE_THUMBNAIL_FAILED_DOWNLOADS, failedDownloads);
    } else {

      // Filter out new failed downloads that are already in the existing list
      const uniqueNewFailedDownloads = failedDownloads.filter(fd =>
        !existingFailedDownloads.some((existingDownload: FailedDownload) =>
        existingDownload.downloadPath === fd.downloadPath
      ));

      // If there are unique new failed downloads, update the storage
      if (uniqueNewFailedDownloads.length > 0) {
        const updatedFailedDownloads = existingFailedDownloads.concat(uniqueNewFailedDownloads);
        await this.localStorageService.writeToStorage(STORAGE_THUMBNAIL_FAILED_DOWNLOADS, updatedFailedDownloads);
      }
    }
  }

  async saveNewCapturesFailedThumbnailDownload(jobCode: string, jobFolderCode: string, fileName: string) {
    const downloadPath = this.urlPrefix + `/Content/Jobs/${jobFolderCode}/Photos/TileLg/` + fileName;
    const failedDownload: FailedDownload = {jobCode, jobFolderCode, downloadPath: downloadPath};
    // Get existing failed downloads from storage
    const existingFailedDownloads = await this.localStorageService.getFromStorage(STORAGE_THUMBNAIL_FAILED_DOWNLOADS) || [];

    if (existingFailedDownloads.length === 0) {
      // write the failed downloads to the storage
      this.localStorageService.writeToStorage(STORAGE_THUMBNAIL_FAILED_DOWNLOADS, [failedDownload]);
    } else {

      const isFailedDownloadAlreadySaved = existingFailedDownloads.some((existingDownload: FailedDownload) =>
        existingDownload.downloadPath === downloadPath
      );

      if (!isFailedDownloadAlreadySaved) {
        const updatedFailedDownloads = existingFailedDownloads.push(failedDownload);
        await this.localStorageService.writeToStorage(STORAGE_THUMBNAIL_FAILED_DOWNLOADS, updatedFailedDownloads);
      }
    }
  }

  private setURLs() {
    this.captureUpdateUrl = this.urlPrefix + '/PhotoCaptures/Update';
    this.captureUploadUrl = this.urlPrefix + '/uploadsfolders/fileupload';
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 404) {
      throw new Error('Upss, it looks like we could not find the resource.');
    } else {
      throw new Error(
        'There was a problem with your request. Please try again later.'
      );
    }
  }
}
