import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AboutService {
  aboutPageInfo = {
    commandCenterUrl: null,
    subscriptionType: 'Lite',
    hiResEnabled: false,
    pushEnabled: false
  };
  constructor() { }
}
