<ion-app>
    <ion-menu *ngIf="isMenuVisible" contentId="main-content">
      <ion-content>

        <div class="nav-logo">
          <img src="../assets/logos/PA+-Logo-Part-White.png" alt="Photo Audit Plus">
        </div>

        <!-------- app side menu groups -------------------------------------------------------------------------------------------->
        <app-side-menu [menuItems]="mainMenuItems" menuTitle="Visual Site Audit"></app-side-menu>

        <app-side-menu [menuItems]="accountMenuItems" menuTitle="Account"></app-side-menu>

        <app-side-menu [menuItems]="helpMenuItems" menuTitle="Help"></app-side-menu>
        <!-- -------------------------------------------------------------------------------------------------------------------- -->

      </ion-content>
    </ion-menu>
      <ion-content class="ion-padding" id="main-content">
        <ion-router-outlet></ion-router-outlet>
      </ion-content>
</ion-app>
