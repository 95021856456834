import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumPipe } from './pipes/enum.pipe';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomePipe } from './pipes/font-awesome.pipe';
import { PhotoFilterComponent } from './components/photo-filter/photo-filter.component';
import { IonicModule } from '@ionic/angular';
import { TakePhotoModalComponent } from '../components/take-photo-modal/take-photo-modal.component';
import { JobDetailsOverviewPageModule } from '../pages/job-details-overview/job-details-overview.module';
import { environment } from 'src/environments/environment';



@NgModule({
  declarations: [
    EnumPipe,
    FontAwesomePipe,
    PhotoFilterComponent,
    TakePhotoModalComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    IonicModule,
    JobDetailsOverviewPageModule,
    // AgmCoreModule.forRoot({
    //   apiKey: environment.googleMapsAPIKey
    // })
  ],
  exports: [
    EnumPipe,
    FontAwesomePipe,
    PhotoFilterComponent
  ]
})
export class SharedModule {

  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIconPacks(fas, fab, far);
  }
 }
