import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConnectedIconColor } from '../lookups/enums';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  isConnectedIconColor$ = new BehaviorSubject<string>(ConnectedIconColor.Success);

  constructor() { }
}
