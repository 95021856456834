<ion-list lines="none">
  <ion-list-header><strong>{{ menuTitle | uppercase}}</strong></ion-list-header>


  <ion-menu-toggle autoHide="false" *ngFor="let item of menuItems; let i = index">
    <ion-item [routerLink]="item.url" routerLinkActive="active" routerDirection="root" detail="false" >
      <ion-icon slot="start" [name]="item.icon"></ion-icon>
      <ion-label>
        <strong>{{item.title}}</strong>
      </ion-label>
    </ion-item>
  </ion-menu-toggle>

</ion-list>
