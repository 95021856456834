import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(private toastCtrl: ToastController) { }

  async showToastMessage(message: string, color: string = 'dark', duration: number = 3000) {
    const toast = await this.toastCtrl.create({
      message,
      color,
      position: 'bottom',
      duration
    });
    await toast.present();
  }

  async dismissToastMessage(){
    await this.toastCtrl.getTop().then(top => {
      if (top) {
        this.toastCtrl.dismiss();
      }
    });
  }
}
