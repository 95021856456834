import { Component, Input, OnInit } from '@angular/core';
import { AppSideMenuItem } from 'src/app/shared/models/app-side-menu-item';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  @Input() menuItems: AppSideMenuItem[];
  @Input() menuTitle: string;
  constructor() { }

  ngOnInit() {}

}
