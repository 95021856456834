/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Job } from '../models/job';
import { FILESYSTEM_JOBS_FOLDER, STORAGE_ABOUT_PAGE_SETTINGS, STORAGE_APP_HAD_INITIAL_LAUNCH, STORAGE_JOB_CAPTURES, STORAGE_MY_JOBS, STORAGE_MY_JOBS_REFERENCE, STORAGE_SITE_URL, STORAGE_THUMBNAIL_FAILED_DOWNLOADS } from 'src/app/shared/lookups/consts';
import { Base64File, BlobFile } from 'src/app/shared/models/CustomFiles';
import { Directory, FileInfo, Filesystem } from '@capacitor/filesystem';
import { FileSystem_JobFolders, Filesystem_JobFolder_PhotosFolders, SettingNames } from '../lookups/enums';
import { Capture } from '../models/capture';
import { ToastMessageService } from './toast-message.service';
import { AboutService } from 'src/app/pages/about/services/about.service';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  storedJobs$ = new BehaviorSubject<any>([]);
  storedJobs: Job[] = [];
  // storageObj: Storage;

  storedJobCaptures$ = new Subject<{
    jobCode: string;
    jobFolderCode: string;
    captures: Capture[];
  }>();

  storedJobsCaptures: {jobCode: string; jobFolderCode: string; captures: Capture[]; }[] = [];

  storedJobsCaptures$ = new BehaviorSubject<{jobCode: string; jobFolderCode: string; captures: Capture[]; }[]>(undefined);



  //keeps track of the codes of the downloaded jobs
  //for quick acces e.g check if the job is already downloaded
  storedJobsReference: string[] = [];

  siteUrl: string;
  siteUrl$ = new BehaviorSubject<string>(null);

  appHadInitialLaunch: boolean;

  private loadedSettings: {
    name: SettingNames;
    value: string | number | boolean;
  }[] = [];

  constructor(
    private storage: Storage,
    private toastmessageService: ToastMessageService,
    private aboutPageService: AboutService
  ) {
    this.siteUrl$.subscribe(value => {{
      if (value !== this.aboutPageService.aboutPageInfo.commandCenterUrl) {
        this.aboutPageService.aboutPageInfo.commandCenterUrl = value;
        this.writeToStorage(STORAGE_ABOUT_PAGE_SETTINGS, this.aboutPageService.aboutPageInfo);
      }
    }});
  }

  public async init() {
    await this.storage
      .create()
      .then((res: Storage) => {
        // this.storageObj = res;
      })
      .catch((error: Error) => {
        console.log(error.message);

        this.toastmessageService.showToastMessage(
          'There was an error when loading the app data.',
          '',
          10000
        );
      });
    await this.getStoredJobsData();
    // this.loadMySettings();
    await this.checkIfAppFirstLaunch();
    await this.setSiteUrl();
  }


  async getSavedImages(
    size: Filesystem_JobFolder_PhotosFolders,
    jobFolderCode: string
  ) {
    const imagePath = `/${FILESYSTEM_JOBS_FOLDER}/${jobFolderCode}/${FileSystem_JobFolders.Photos}/${size}`;

    return await Filesystem.readdir({
      directory: Directory.Data,
      path: imagePath,
    });
  }

  async getFolderItems(folderPath: string) {
    return await Filesystem.readdir({
      directory: Directory.Data,
      path: folderPath,
    });
  }

  async readImageFileData(size: Filesystem_JobFolder_PhotosFolders, jobFolderCode: string, fileName: string) {
    const imagePath = `/${FILESYSTEM_JOBS_FOLDER}/${jobFolderCode}/${FileSystem_JobFolders.Photos}/${size}/${fileName}`;

    return await Filesystem.readFile({
      directory: Directory.Data,
      path: imagePath,
    });
  }

  async readFileData(filePath: string) {
    return await Filesystem.readFile({
      path: filePath,
    });
  }

  async saveJob(job: Job) {
    //add new job
    this.storedJobs.push(job);
    this.storedJobsReference.push(job.Code);

    //push new job list to refresh the view
    this.storedJobs$.next(this.storedJobs);

    //store the new job list on the device
    this.writeToStorage(STORAGE_MY_JOBS, this.storedJobs).then(() => {
      this.writeToStorage(STORAGE_MY_JOBS_REFERENCE, this.storedJobsReference);
    });
  }

  async saveUpdatedJob(jobCode: string) {
    return this.writeToStorage(STORAGE_MY_JOBS, this.storedJobs)
    .then(() => console.log('Job updated successfully.'))
    .catch((error: Error) =>
    console.log('Error when saving the job: ', error.message)
    );
  }

  async deleteJob(job: Job) {
    const jobIndex = this.storedJobs.indexOf(job);
    const jobReferenceIndex = this.storedJobs.indexOf(job);

    //delete a job
    this.storedJobs.splice(jobIndex, 1);

    //push new job list to refresh the view
    this.storedJobs$.next(this.storedJobs);

    //delete the job captures
    await this.storage.remove(STORAGE_JOB_CAPTURES + job.Code);

    //delete job reference
    this.storedJobsReference.splice(jobReferenceIndex, 1);

    //store the new job list on the device
    return await this.writeToStorage(STORAGE_MY_JOBS, this.storedJobs)
    .then(
      () => {
        const path = `/${FILESYSTEM_JOBS_FOLDER}/${job.JobFolders[0].Code}`;
        this.deleteFolderFromFilesystem(path);
        //store the new jobs reference
        this.writeToStorage(STORAGE_MY_JOBS_REFERENCE, this.storedJobsReference);
      }
    );
  }

  getLoadedSettings() {
    return this.loadedSettings;
  }

  async writeToStorage(storageKey: string, data: any) {
    await this.storage.set(storageKey, data);
  }

  async deleteKeyToStorage(storageKey: string) {
    await this.storage.remove(storageKey);
  }

  async getFromStorage(storageKey: string) {
    return await this.storage.get(storageKey);
  }

  async createDirectoryInFilesystem(path: string) {
    try {
      const result = await Filesystem.mkdir({
        path,
        directory: Directory.Data,
        recursive: true
      });
    } catch (e) {
      console.error('Unable to create directory', e);
    }
  }

  async saveBlobToFilesystem(blob: Blob, path: string) {

    //filesystem is accepting only string type as a data,
    //therefore the blob needs to be converted
    let convertedFileData: string;
    await this.convertFileToBase64(blob)
    .then((fileAsString: string) => {
      convertedFileData = fileAsString.substring(fileAsString.indexOf(',') + 1);
    })
    .catch((error: Error) => console.log(error.message));

    //save file to a filesystem
    await Filesystem.writeFile({
      directory: Directory.Data,
      path,
      data: convertedFileData,
      recursive: true,
    });
  }

  async saveBase64ImageToFileSystem(file: Base64File, path: string) {
    //save file to a filesystem
    await Filesystem.writeFile({
      directory: Directory.Data,
      path,
      data: file.data,
      recursive: true,
    })
    .catch((error: Error) => {
      console.log(error.message);
    });
  }

  async saveFailedUploadFileToFilesystem(file: BlobFile){
    const jobFolderCode = file.name.substring(0, file.name.indexOf('_'));
    const filePath = `${FILESYSTEM_JOBS_FOLDER}/${jobFolderCode}/${FileSystem_JobFolders.Outbox}/${file.name}`;
    return this.saveBlobToFilesystem(file.data, filePath)
    .catch((error: Error) => {
      console.log('Something went wrong when saving the image.', error.message);
    });
  }

  async saveBlobToFileSystem(file: BlobFile, jobFolderCode: string, photoFolder: Filesystem_JobFolder_PhotosFolders){
    const filePath = `${FILESYSTEM_JOBS_FOLDER}/${jobFolderCode}/${FileSystem_JobFolders.Photos}/${photoFolder}/${file.name}`;

    //filesystem is accepting only string type as a data,
    //therefore the blob needs to be converted
    let convertedFileData: string;
    await this.convertFileToBase64(file.data).then((fileAsString: string) => {
      convertedFileData = fileAsString.substring(fileAsString.indexOf(',') + 1);
    });

    //save blob to a filesystem
    return Filesystem.writeFile({
      directory: Directory.Data,
      path: filePath,
      data: convertedFileData,
      recursive: true,
    });
  }

  async deleteFolderFromFilesystem(path: string, recursive = true) {
    await Filesystem.rmdir({
      directory: Directory.Data,
      path,
      recursive
    }).catch((error: Error) => console.log(error.message));
  }

  async deleteFileFromFilesystem(path: string) {
    await Filesystem.deleteFile({
      path
    }).catch((error: Error) => console.log(error.message));
  }

  async getAllFailedUploads() {
    try {
        const jobFolderItems = await this.getFolderItems(FILESYSTEM_JOBS_FOLDER);

        if (jobFolderItems.files.length === 0) {
          return []; // Return empty array if no job folders
        }

        const allPendingUploads: FileInfo[] = [];

        for (const jobFolder of jobFolderItems.files) {
          const outboxFolderPath = `/${FILESYSTEM_JOBS_FOLDER}/${jobFolder.name}/${FileSystem_JobFolders.Outbox}`;

          if (await this.checkDirectoryExists(outboxFolderPath)) {
            try {
              const outboxItems = await this.getFolderItems(outboxFolderPath);
              if (outboxItems.files.length > 0) {
                allPendingUploads.push(...outboxItems.files);
              }
            } catch (error) {
              console.log(error.message); // Log the error but continue processing
            }
          }
        }

        return allPendingUploads; // Return collected files
    } catch (error) {
        throw new Error('Something went wrong when reading from device storage');
    }
}


  async getFailedUploadsByJobFolderCode(jobFolderCode: string) {
    const outboxFolderPath = `/${FILESYSTEM_JOBS_FOLDER}/${jobFolderCode}/${FileSystem_JobFolders.Outbox}`;

    const directoryExists = await this.checkDirectoryExists(outboxFolderPath);
    if (!directoryExists) {
      // Throw an error to indicate the directory doesn't exist.
      throw new Error("Directory does not exist");
    }

    try {
      const outboxItems = await this.getFolderItems(outboxFolderPath);
      return outboxItems.files.length > 0 ? outboxItems.files : [];
    } catch (error) {
      throw error;
    }
  }


  async checkDirectoryExists(directoryPath: string) {
    try {
      const statResult = await Filesystem.stat({
        path: directoryPath,
        directory: Directory.Data, // Use the appropriate directory type
      });
      if (statResult.type === 'directory') {
        console.log('Directory exists:', directoryPath);
        return true;
      } else {
        console.log('Path exists but is not a directory:', directoryPath);
        return false;
      }
    } catch (error) {
      if (error.message.includes('ENOENT')) {
      console.log('Directory does not exist:', directoryPath);
      return false;
      } else {
        return false;
      }
    }
  }

  private convertFileToBase64(data: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(data);
    });
  }

  //check if the app is running for the first time
  private async checkIfAppFirstLaunch() {
    await this.getFromStorage(STORAGE_APP_HAD_INITIAL_LAUNCH)
    .then((result) => {
      this.appHadInitialLaunch = result;
      if (!result) {
        this.setAppDefaults();
      }
    })
    .catch((error: Error) => {
      console.log(`Error: ${error.message}`);
    });
  }

  private async setSiteUrl(): Promise<void> {
    const siteUrl = await this.getFromStorage(STORAGE_SITE_URL);

    if (siteUrl) {
      this.siteUrl$.next(siteUrl);
    }
  }

  //this sets the default storage keys that are used across the app
  private async setAppDefaults() {
    await this.writeToStorage(STORAGE_APP_HAD_INITIAL_LAUNCH, true);
  }

  private async getStoredJobsData() {
    // Get stored jobs
    const jobs = await this.storage.get(STORAGE_MY_JOBS);
    if (jobs) {
      this.storedJobs = jobs;
      this.storedJobs$.next(this.storedJobs);
      this.getStoredJobsCaptures();
    } else {
      this.storedJobs$.next([]);
    }

    // Get stored jobs reference
    const jobsReference = await this.storage.get(STORAGE_MY_JOBS_REFERENCE);
    if (jobsReference) {
      this.storedJobsReference = jobsReference;
    }

  }

  //gets the captures for each stored job
  private getStoredJobsCaptures() {
    const storedJobsCaptures = [];
    for (const job of this.storedJobs) {
      this.getFromStorage(STORAGE_JOB_CAPTURES + job.Code).then(
        (captures) => {
          storedJobsCaptures.push({
            jobCode: job.Code,
            jobFolderCode: job.JobFolders[0].Code,
            captures,
          });
          this.storedJobCaptures$.next({
            jobCode: job.Code,
            jobFolderCode: job.JobFolders[0].Code,
            captures,
          });
        }
      );
    }

    this.storedJobsCaptures$.next(storedJobsCaptures);
    this.storedJobsCaptures = storedJobsCaptures;
  }
}
