/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Job } from '../models/job';
import { Capture } from '../models/capture';
import { LocalStorageService } from './local-storage.service';
import { CaptureService } from './capture.service';
import { Note } from '../models/note';
import { FILESYSTEM_JOBS_FOLDER, STORAGE_JOB_CAPTURES, STORAGE_MY_JOBS, STORAGE_THUMBNAIL_FAILED_DOWNLOADS } from '../lookups/consts';

import { FailedDownload } from '../lookups/types';
import { BlobFile } from '../models/CustomFiles';
import { FileSystem_JobFolders, Filesystem_JobFolder_PhotosFolders, PhotoCaptureStatus } from '../lookups/enums';
import { CaptureUpdate } from '../models/capture-update';



@Injectable({
  providedIn: 'root',
})
export class JobService {
  public currentViewedJob: Job;
  public currentViewedJobCaptures: Capture[];

  // list of stored jobs
  jobList: Job[] = [];

  private urlPrefix = '';

  private dataUrl = ''; //this.urlPrefix + '/jobs/appdownload';
  private capturesDataUrl = ''; //this.urlPrefix + '/jobs/AppGetCaptures';

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private captureService: CaptureService
  ) {
    this.localStorageService.siteUrl$.subscribe((res) => {
      this.urlPrefix = res;
      this.setURLs();
    });

    this.localStorageService.storedJobs$.subscribe((jobs) => {
      if (jobs.length > 0) {
        this.jobList = jobs;
      }
    });
  }

  async updateJobData() {
    let allFailedCaptureUpdates = [];
    const jobsWithCaptures = this.localStorageService.storedJobsCaptures;

    // map each job to to update task
    const updateTasks = jobsWithCaptures.map(async (job) => {
      return await this.updateJobState(job);
    });

    // Wait for all the update tasks to complete, each task returns an array of failed updates
    const results = await Promise.all(updateTasks);

    allFailedCaptureUpdates = results.flat();

    // Check if there are any failed downloads to store
    if (allFailedCaptureUpdates.length > 0) {
      this.captureService.saveCapturesFailedThumbnailDownloads(allFailedCaptureUpdates);
    }

    // update stored captures and photo object for each job
    for (const job of jobsWithCaptures) {
      await this.localStorageService.writeToStorage(STORAGE_JOB_CAPTURES + job.jobCode, job.captures)
      .catch((error: Error) => console.log(error.message));
    }

    await this.checkForPendingUploadsAndDownloads();
  }

  private async updateJobState(job: {jobCode: string; jobFolderCode: string; captures: Capture[];}) {
    const jobFailedCaptureUpdates: FailedDownload[] = [];

    try {
      const captures = await firstValueFrom(this.captureService.getCapturesByJobCode(job.jobCode));

      // Update captures for the job
      for (const capture of captures) {
        const existingCaptureIndex = job.captures.findIndex(c => c.Code === capture.Code);

        if (existingCaptureIndex >= 0) {
          // Update existing capture
          job.captures[existingCaptureIndex] = capture;
        } else {
          try {
            // download capture thumbnail
            const file: BlobFile = await this.captureService.downloadCaptureThumbnail(job.jobCode, job.jobFolderCode, capture.FileName);

            const filePath = `${FILESYSTEM_JOBS_FOLDER}/${job.jobFolderCode}/${FileSystem_JobFolders.Photos}/${Filesystem_JobFolder_PhotosFolders.TileLg}/${file.name}`;
            await this.localStorageService.saveBlobToFilesystem(file.data, filePath)
              .then(() => {
                // Add new capture to the job captures
                job.captures.push(capture);
              })
              .catch((storageError) => {
                console.error('Failed to save to filesystem:', storageError);
                const failedUpdate = {jobCode: job.jobCode, jobFolderCode: job.jobFolderCode, downloadPath: capture.FileName,};
                jobFailedCaptureUpdates.push(failedUpdate);
              });
          } catch (error) {
            console.log(error.message);

            const failedDownload: FailedDownload = {jobCode: job.jobCode, jobFolderCode: job.jobFolderCode, downloadPath: capture.FileName,};
            jobFailedCaptureUpdates.push(failedDownload);
          }
        }
      }
    } catch (error) {
      console.error('Error syncing data captures for job', job, error);
    }

    return jobFailedCaptureUpdates;
  }

  async checkForPendingUploadsAndDownloads() {
    // Task 1: Get and download pending thumbnails
    const failedDownloadsTask = this.localStorageService.getFromStorage(STORAGE_THUMBNAIL_FAILED_DOWNLOADS)
      .then((failedDownloads: FailedDownload[]) => {
        if (failedDownloads && failedDownloads.length > 0) {
          this.captureService.downloadThumbnailsByFilePath(failedDownloads);
        }
      })
      .catch(errorMessage => {
        console.error(errorMessage);
      });

    // Task 2: Get and process all failed uploads
    const failedUploadTask = this.localStorageService.getAllFailedUploads()
      .then(async allPendingUploads => {
        if (allPendingUploads && allPendingUploads.length > 0) {
          const xmlFailedUploads = allPendingUploads.filter(f => f.name.endsWith('.xml'));
          const jpgFailedUploads = allPendingUploads.filter(f => f.name.endsWith('.jpg'));

          // upload .xml files first, as if we upload the .jpg file first it does not appear on the portal
          await this.captureService.uploadFilesByFileInfo(xmlFailedUploads);
          await this.captureService.uploadFilesByFileInfo(jpgFailedUploads);
        }
      })
      .catch((error: Error) => {
        console.log(error.message);
      });

    // Wait for both tasks to complete
    await Promise.all([failedDownloadsTask, failedUploadTask]);
  }


  getByRecordLocator(recordLocator: string): Observable<Job> {
    const body = new FormData();
    body.set('RecordLocator', recordLocator);
    console.log(' data url', this.dataUrl);

    return this.http.post<Job>(this.dataUrl, body).pipe(catchError(this.handleError));
  }

  getCapturesDataByJobCode(jobCode: string): Observable<Capture[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('id', jobCode);
    return this.http.get<Capture[]>(this.capturesDataUrl, {headers: headers, params: params,})
      .pipe(
        catchError(this.handleError),
        finalize(() => {})
      );
  }

  async saveNote(note: Note, captureCode: string) {
    const capture: Capture = this.currentViewedJobCaptures.find(
      (c) => c.Code === captureCode
    );

    // post the note/capture update to the api
    this.captureService.postCaptureUpdateAsync(note, captureCode).subscribe({
      next: async (response) => {
        capture.Notes = response.Notes;

        //save note/update capture data stored on the local device
        return await this.localStorageService.writeToStorage(STORAGE_JOB_CAPTURES + this.currentViewedJob.Code, this.currentViewedJobCaptures)
          .catch((error: Error) => {
            console.log(error);
            throw new Error('Failed, when saving the note.');
          });
      },
      error: (error: Error) => {
        console.log(error.message);
        throw new Error('Failed, when uploading the note to the server.');
      },
    });
  }

  updateJobPhotoObject(jobCapturesObject: {jobCode: string; jobFolderCode: string; captures: Capture[];}, captureUpdate: CaptureUpdate) {
    const jobPhoto = this.jobList
      .find((job) => job.Code === jobCapturesObject.jobCode)
      .JobFolders[0].PhotoLogs[0].PhotoLogSections.find(
        (pls) => pls.Code === captureUpdate.code.substring(0, pls.Code.length)
      )
      .PhotoSets.find(
        (ps) => ps.Code === captureUpdate.code.substring(0, ps.Code.length)
      )
      .Photos.find(
        (p) => p.Code === captureUpdate.code.substring(0, p.Code.length)
      );

    const photoCaptures = jobCapturesObject.captures.filter(
      (c) => c.Code.substring(0, jobPhoto.Code.length) === jobPhoto.Code
    );

    if (
      !jobPhoto.IsSeries &&
      photoCaptures.some((c) => c.Status === PhotoCaptureStatus.Accepted)
    ) {
      jobPhoto.IsComplete = true;
    }

    if (
      jobPhoto.IsSeries &&
      photoCaptures.some((c) => c.Status === PhotoCaptureStatus.Rejected)
    ) {
      jobPhoto.IsComplete = false;
    }

    if (
      jobPhoto.IsSeries &&
      photoCaptures.some((c) => c.Status === PhotoCaptureStatus.Flagged)
    ) {
      jobPhoto.IsComplete = false;
    }

    if (
      jobPhoto.IsSeries &&
      jobCapturesObject.captures.every(
        (c) => c.Status === PhotoCaptureStatus.Accepted
      )
    ) {
      jobPhoto.IsComplete = true;
    }

    this.localStorageService.writeToStorage(STORAGE_MY_JOBS, this.jobList)
      .then(() => {
        this.localStorageService.storedJobCaptures$.next(jobCapturesObject);
        console.log('Job updated.');
      });
  }

  updateJobCaptures(
    jobCapturesObject: {
      jobCode: string;
      jobFolderCode: string;
      captures: Capture[];
    },
    captureUpdate: CaptureUpdate
  ) {
    const captureToUpdate = jobCapturesObject.captures.find(
      (capture) => capture.Code === captureUpdate.code
    );

    if (captureToUpdate) {
      captureToUpdate.ExportRotate = captureUpdate.exportRotate;
      captureToUpdate.Notes = captureUpdate.allNotes;

      if (
        captureUpdate.status !== PhotoCaptureStatus.NoChange &&
        captureUpdate.status !== PhotoCaptureStatus.Renewing
      ) {
        captureToUpdate.Status = captureUpdate.status;
      }

      this.localStorageService.writeToStorage(STORAGE_JOB_CAPTURES + jobCapturesObject.jobCode, jobCapturesObject.captures)
        .then(() => console.log('Capture updated.'));
    }
  }

  private setURLs() {
    this.dataUrl = this.urlPrefix + '/jobs/appdownload';
    this.capturesDataUrl = this.urlPrefix + '/jobs/AppGetCaptures';
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 404) {
      throw new Error('Upss, it looks like we could not find the resource.');
    } else {
      const errorMessage = 'There was a problem with your request. Please try again later.';
      //TODO: if statement is for testing remove before deployed to production
      // if(environment.production) {
      //   errorMessage = error.message;
      // }
      throw new Error(errorMessage);
    }
  }
}
