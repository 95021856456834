import { Pipe, PipeTransform } from '@angular/core';
import * as enums from '../lookups/enums';


@Pipe({
  name: 'enum'
})
export class EnumPipe implements PipeTransform {

  transform(value: number, enumName: string): unknown {

    if (enums[enumName]) {
      return enums[enumName][value] !== undefined ? enums[enumName][value] : value;
    }
    return value;
  }

}
