<ion-grid>
  <ion-row>

    <ion-col size="4" *ngFor="let filter of filters">
      <!-- <div> -->
        <div lines="none" (click)="applyFilter(filter)" class="filter-element" [class.filterApplied]="filter.isApplied">
          <div class="status-header">
            <ion-label>
              {{ filter.itemCount }}
            </ion-label>

            <fa-icon slot="end" style="margin-left: 0px;" [style.color]="filter.color" [icon]="filter.icon"></fa-icon>
          </div>
          <div class="status-text">
            <ion-label>
              <p>{{ filter.displayText }}</p>
            </ion-label>
          </div>
        </div>

        <!-- <ion-item lines="none">
          <ion-label>
            <p>{{ filter.displayText }}</p>
          </ion-label>
        </ion-item> -->

      <!-- </div> -->
    </ion-col>

  </ion-row>
</ion-grid>
